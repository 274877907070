<template>
  <router-link
    :to="{ name: 'student-advisor-profile', params: { id: advisor.id } }"
    class="card card-sm card--elevated p-relative h-100 overlay--show card-lg overlay--primary-dodger-blue card-group-row__card"
    style="scroll-margin-top: 70px"
  >
    <!-- <div class="card card-sm card--elevated p-relative h-100" style="scroll-margin-top: 70px"> -->
    <div style="position: relative; text-align: center; color: white">
      <span
        class="js-image d-flex justify-content-center clickable-item overlay"
        style="width: 100%"
        data-position="left"
        :data-height="168"
      >
        <b-img
          v-if="advisor.image"
          :src="advisor.image"
          style="border-top-left-radius: 0.5rem; border-top-right-radius: 0.5rem; height: 250px"
          class="career-card-img"
        />
        <div
          v-else
          style="border-top-left-radius: 0.5rem; border-top-right-radius: 0.5rem; height: 250px; width: 100%"
          class="bg-primary"
        >
          <md-icon class="mt-4" style="font-size: 130px; color: #ffffff">person</md-icon>
        </div>
      </span>
      <div
        style="
          position: absolute;
          bottom: 0px;
          background: linear-gradient(to bottom, rgba(255, 0, 0, 0), rgba(20, 18, 18, 1));
          width: 100%;
        "
      >
        <div class="d-flex align-items-start ml-3" style="color: white; font-size: 24px">
          <strong>{{ advisor.first_name }} {{ advisor.last_name }}</strong>
        </div>
        <div class="d-flex align-items-start ml-3 mb-1" style="color: white" v-if="get(advisor, 'experience.length')">
          {{ get(advisor, 'experience[0].title', 'Advisor') }}
        </div>
      </div>
    </div>

    <div class="card-footer d-flex flex-column flex-grow-1 pt-0">
      <p class="text-70 adv-desc my-auto">
        {{ trim(stripHtmlTags(get(advisor, 'description'))) || 'No description given' }}
      </p>

      <div class="d-flex justify-content-end">
        <router-link
          :to="{ name: 'student-advisor-profile', params: { id: advisor.id } }"
          class="btn btn-primary w-100"
        >
          View Profile
        </router-link>
      </div>
    </div>
    <!-- </div> -->
  </router-link>
</template>
<script>
import { trim, truncate, get } from 'lodash';
import { formatDateTime, stripHtmlTags } from '@/common/utils';
export default {
  props: {
    advisor: { type: Object, default: () => [] },
  },
  components: {},
  methods: {
    trim,
    truncate,
    formatDateTime,
    get,
    stripHtmlTags,
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.adv-desc {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
</style>
