<template>
  <div class="page-section">
    <div :class="containerClass">
      <h2 class="text-center mb-2">Advisors</h2>
      <p class="text-center mb-4">
        Connect with experienced healthcare professionals who can help you navigate your career path.
      </p>

      <div class="candidates_container">
        <div class="d-flex">
          <b-btn
            v-if="enableSidebarToggle !== false"
            v-fmv-toggle.filters
            size="sm"
            variant="white"
            class="ml-auto mb-2"
          >
            <md-icon left>tune</md-icon>
            <span>Filters</span>
          </b-btn>
        </div>

        <page-separator
          :title="
            !isLoading
              ? `showing ${totalAdvisors ? `${pageFromCount}-${pageToCount}` : 0} of ${totalAdvisors} Advisors`
              : ''
          "
        />
        <div class="page-section d-flex justify-content-center" v-if="!allAdvisors.length && !isLoading">
          <div class="card card-block card-stretch card-height col-md-8">
            <div class="card-body text-center">
              <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
              <h4>No Advisors</h4>

              <p class="text-muted">Couldn't find any Advisors.</p>
            </div>
          </div>
        </div>

        <b-skeleton-wrapper :loading="isLoading" v-else>
          <template #loading>
            <div class="row card-group-row mb-lg-12pt home-card">
              <div class="col-md-6 col-lg-6 card-group-row__col" v-for="item in [1, 2]" :key="item">
                <div class="card overlay--show card-lg overlay--primary-dodger-blue card-group-row__card">
                  <div class="card-header d-flex align-items-center">
                    <a href="#" @click.prevent class="card-title flex"> <b-skeleton width="100%"></b-skeleton></a>
                  </div>
                  <div class="card-body d-flex flex-column">
                    <div class="d-flex align-items-center">
                      <div class="flex">
                        <div class="mb-16pt" :class="{ 'd-flex align-items-center': !isMobSmallScreen }">
                          <div class="mr-12pt" :class="{ 'd-flex justify-content-center mb-3': isMobSmallScreen }">
                            <a
                              href="#"
                              @click.prevent
                              class="avatar avatar-xl overlay js-overlay overlay--primary rounded-circle p-relative o-hidden mb-16pt align-self-center"
                              style="border: 1px solid #dfe2e6"
                            >
                              <b-skeleton-img class="avatar-img" width="160" height="100%"></b-skeleton-img>
                            </a>
                          </div>
                          <div class="flex">
                            <div class="mb-0" style="text-transform: none">
                              <b-skeleton width="100%"></b-skeleton>
                              <b-skeleton width="55%"></b-skeleton>
                              <b-skeleton width="70%"></b-skeleton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <div class="row card-group-row mb-lg-12pt home-card">
            <div
              v-for="advisor in allAdvisors"
              :key="advisor.id"
              class="col-lg-6 col-md-6 col-sm-12 clickable-item mb-3"
            >
              <advisor-card :advisor="advisor" />
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
    </div>
    <pagination
      v-if="totalAdvisors > allAdvisors.length"
      v-model="currentPage"
      :total-rows="totalAdvisors"
      :per-page="perPage"
      @change="onPageChange"
    />
    <!-- aria-controls="universities-table" -->
  </div>
</template>

<script>
import AdvisorCard from './AdvisorCard.vue';
import { debounce, get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Page from '@/components/Page.vue';
import Filters from './Filters/Filters.vue';
import FiltersSidebar from './Filters/FiltersSidebar.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import Pagination from '@/components/Ui/Pagination.vue';

export default {
  name: 'AdvisorList',
  components: { AdvisorCard, PageSeparator, Pagination },
  extends: Page,

  data() {
    return {
      title: 'Advisors',
      windowWidth: window.innerWidth,
      firstLoad: true,
      isLoading: false,
      perPage: 14,
      currentPage: 1,
      totalAdvisors: 0,
      pageFromCount: 0,
      pageToCount: 0,
      allAdvisors: [],
      filters: {
        programs_id: null,
        background: null,
      },
    };
  },

  computed: {
    ...mapGetters('advisors', ['getAdvisorFilters']),

    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },

    subLayout() {
      return this.windowWidth > 992;
    },
    subLayoutDrawer() {
      return this.windowWidth > 992 ? FiltersSidebar : Filters;
    },
    subLayoutDrawerId() {
      return 'filters';
    },
    enableSidebarToggle() {
      return this.windowWidth <= 992;
    },
  },

  methods: {
    get,
    ...mapActions('advisors', ['getAllAdvisors']),

    async fetchAdvisors(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllAdvisors({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        // ...(this.searchTerm && { search: this.searchTerm }),
        ...this.filters,
        ...params,
      });
      this.allAdvisors = response.data.results;
      this.currentPage = pageNum;
      this.totalAdvisors = response.data.count;
      this.isLoading = false;
      this.pageFromCount = (this.currentPage - 1) * this.perPage + 1;
      this.pageToCount = this.pageFromCount + get(response.data, 'results.length', 0) - 1;
    },
    onPageChange(pageNum) {
      this.fetchAdvisors(pageNum);
    },
    debouncedSearchUniversities: debounce(vm => {
      vm.fetchUniversities();
    }, 500),
  },
  watch: {
    getAdvisorFilters: {
      deep: true,
      handler(filterValues) {
        this.filters = filterValues;
        this.fetchAdvisors();
      },
    },
  },
  async mounted() {
    this.fetchAdvisors();
  },
};
</script>
