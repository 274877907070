<template>
  <b-modal
    ref="transcriptModal"
    hide-footer
    title="Generate Transcript"
    centered
    lazy
    @hide="hideModal"
    size="md"
    style="overflow: visible;"
  >
    <b-form @submit.prevent="handleSave()">
      <small v-html="modalDescription"></small>
      <b-form-group label="Program Status" style="margin-top: 15px;" label-for="progStatus" label-class="form-label">
        <v-select
          id="progStatus"
          placeholder="Select program status"
          class="form-control v-select-custom"
          label="text"
          v-model="progStatus"
          :reduce="ins => ins.value"
          :options="progStatusOptions"
          :clearable="false"
          @input="onProgStatusChange"
        ></v-select>
      </b-form-group>
      <b-form-group v-if="progStatus === 'incomplete'" label="Enrollment Status" label-for="status" label-class="form-label">
        <v-select
          class="form-control v-select-custom"
          placeholder="Select enrollment status"
          label="text"
          v-model="status"
          :reduce="ins => ins.value"
          :options="statuses"
          :clearable="false"
          style="flex: 1;"
        ></v-select>
      </b-form-group>
      <b-form-group
        v-if="progStatus === 'complete'"
        label="Completion Date"
        label-for="completionDate"
        label-class="form-label"
      >
        <b-form-datepicker
          v-model="completionDate"
          container="body"
          required
          dropup
          @input="setError"
          style="flex: 1;"
        ></b-form-datepicker>
        <div v-if="dateError" class="text-danger" style="font-size: small;">*This field is required</div>
      </b-form-group>
      <b-form-group v-if="progStatus === 'complete'" label="Grade" label-for="grade" label-class="form-label">
        <v-select
          class="form-control v-select-custom"
          placeholder="Select program grade"
          label="text"
          v-model="grade"
          :reduce="ins => ins.value"
          :options="grades"
          :clearable="false"
          style="flex: 1;"
        ></v-select>
      </b-form-group>

      <b-form-group v-if="progStatus === 'complete'" label="Hours" label-for="hours" label-class="form-label">
        <b-form-input id="hours" placeholder="Enter program hours" type="number" v-model="hours" required />
      </b-form-group>
      <div class="d-flex justify-content-between mt-3 w-100">
        <!-- :disabled="progStatus === null || isSaving || isDownloading" -->
        <button
          type="submit"
          :disabled="actionsDisabled"
          @click.prevent="handleSave()"
          class="btn btn-primary d-flex justify-content-center align-items-center flex-grow-1 mr-2"
          style="flex: 1;"
        >
          <b-spinner class="align-middle mr-2" small v-if="isSaving"></b-spinner>
          <div v-else><md-icon class="mr-2">save</md-icon>Save</div>
        </button>
        <button
          @click.prevent="handleDownload()"
          :disabled="actionsDisabled"
          class="btn btn-secondary d-flex justify-content-center align-items-center flex-grow-1 ml-2"
          style="flex: 1;"
        >
          <b-spinner class="align-middle mr-2" small v-if="isDownloading"></b-spinner>
          <div v-else><md-icon class="mr-2">print</md-icon>Save & Download</div>
        </button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { get } from 'lodash';
import { isEmpty } from 'lodash';
export default {
  name: 'TranscriptModal',
  props: {
    showModal: { type: Boolean, default: false },
    student: { type: Object, default: () => {} },
  },
  data() {
    return {
      progStatus: null,
      progStatusOptions: [
        { text: 'Complete', value: 'complete' },
        { text: 'Incomplete', value: 'incomplete' },
      ],
      completionDate: null,
      grade: 'Pass',
      hours: '',
      isDownloading: false,
      isSaving: false,
      status:'',
      grades: [
        {
          text: 'Pass',
          value: 'Pass',
        },
        {
          text: 'Fail',
          value: 'Fail',
        },
      ],
      statuses: [
        {
          text: 'Dropped',
          value: 'dropped',
        },
        {
          text: 'Enrolled',
          value: 'enrolled',
        }
        
      ],
      transcript: null,
      dateError: false,
    };
  },
  watch: {
    showModal(value) {
      if (value) {
        this.getProgramStatus();
        this.$refs.transcriptModal.show();
      }
    },
  },
  computed: {
    modalDescription() {
      if (!this.student) return '';
      return `Generate and update the transcript for <b>${this.student.user.first_name}</b> in the <b>${get(
        this.student,
        'enrolled_program.title',
      )}</b> program`;
    },
    actionsDisabled() {
      return this.isDownloading || this.isSaving || this.progStatus === null ||
          (this.progStatus === 'complete' && (!this.completionDate || !this.grade || !this.hours));
    }
  },
  methods: {
    ...mapActions('student', ['getStudentProgramStatus']),
    ...mapActions('student', ['updateStudentTranscript']),
    ...mapActions('fileDownload', ['downloadFile']),
    get,

    onProgStatusChange(value) {
      if (value === 'incomplete') {
        this.status = 'enrolled';
      }
    },

    async getProgramStatus() {
      if (this.student.id) {
        const response = await this.getStudentProgramStatus({ id: this.student.id });
        this.transcript = response.data.data;
        this.progStatus = this.transcript.date_completed;
        this.grade = this.transcript.grade;
        this.completionDate = this.transcript.date_completed;
        if (!isEmpty(this.transcript)) {
          if (!this.transcript.date_completed) {
            this.progStatus = this.progStatusOptions[1].value;
            
          } else {
            this.progStatus = this.progStatusOptions[0].value;
            this.grade = this.transcript.grade;
            this.completionDate = this.transcript.date_completed;
            this.hours = this.transcript.extras.hours;
            
          }
          this.status = this.transcript.extras.status;
        }
      }
    },
    setError() {
      this.dateError = false;
    },
    hideModal() {
      this.$emit('close', true);
    },
    async updateStudTranscript() {
      const data = {
        id: this.student.id,
        date_completed: this.progStatus === 'complete' ? this.completionDate : null,
        grade: this.progStatus === 'complete' ? this.grade : null,
        extras: { 
          hours: this.progStatus === 'complete' ? this.hours : '', 
          status: this.progStatus === 'incomplete' ? this.status : 'completed' 
        },
        
      };
      const response = await this.updateStudentTranscript(data);
      this.resetForm();
      return response;
    },
    resetForm() {
      this.progStatus = null;
      this.grade = 'Pass';
      this.completionDate = null;
      this.hours = '';
      this.status = '';
    },
    async handleSave() {
      if (this.progStatus === 'complete') {
        this.dateError = !this.completionDate;
      }
      if (this.dateError) {
        return;
      } else {
        this.isSaving = true;
        try {
          await this.updateStudTranscript();
          this.$refs.transcriptModal.hide();
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
        this.isSaving = false;
      }
    },
    async downloadGivenFile(data) {
      try {
        const h = this.$createElement;
        this.makeToast({
          variant: 'primary',
          msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'Downloading File']),
          toaster: 'b-toaster-bottom-left',
        });

        await this.downloadFile({ fileUrl: data.url });

        this.makeToast({
          variant: 'success',
          msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'File Downloaded']),
          toaster: 'b-toaster-bottom-left',
        });
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isDownloading = false;
    },

    async handleDownload() {
      this.isDownloading = true;
      try {
        const resp = await this.updateStudTranscript();
        this.downloadGivenFile(resp.data.data);
        this.isDownloading = false;
        this.$refs.transcriptModal.hide();
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isDownloading = false;
    },
  },
};
</script>
