<template>
  <div style="margin-top: -16px">
    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <div class="page-section bg-dark border-bottom-white">
          <div
            class="d-flex flex-column flex-md-row align-items-center text-center text-md-left"
            :class="containerClass"
          >
            <fmv-avatar :title="true" rounded size="xxl" no-link :class="!isMobSmallScreen ? 'mr-3' : ''">
              <b-skeleton-img height="100px" width="100px" circle no-aspect></b-skeleton-img>
            </fmv-avatar>

            <div class="flex mb-32pt mb-md-0">
              <h2 class="text-white mb-0"><b-skeleton width="85%"></b-skeleton></h2>
              <p class="lead text-white-50">
                <b-skeleton width="60%"></b-skeleton>
              </p>
            </div>
            <b-skeleton type="button" class="ml-2"></b-skeleton>
          </div>
        </div>
        <b-skeleton width="60%"></b-skeleton>
        <b-skeleton width="60%"></b-skeleton>
        <b-skeleton width="60%"></b-skeleton>
      </template>

      <div class="bg-dark page-section employer-header-bg border-bottom-white mb-10pt img-fluid">
        <div :class="containerClass">
          <router-link :to="{ name: 'student-advisors' }" style="text-decoration: underline" class="text-white">
            <span class="material-icons icon-18pt text-80">navigate_before</span> Back to Advisors
          </router-link>
        </div>

        <div class="overlay__content page-section employer-profile-cover-ol">
          <div
            class="d-flex flex-column flex-md-row align-items-center text-center text-md-left"
            :class="containerClass"
          >
            <b-img
              :src="advisor.image"
              width="100"
              height="100"
              alt="Logo"
              v-if="advisor.image"
              rounded="circle"
              style="object-fit: cover;"
            />

            <div class="ml-3">
              <div class="title text-white">
                <h1 class="text-white my-0">{{ advisor.full_name }} {{ advisor.last_name }}</h1>
              </div>
              <h4
                :style="
                  isMobSmallScreen
                    ? 'font-size: 1.1rem; font-weight: 400 !important;'
                    : 'font-size: 1.3rem; font-weight: 400 !important;'
                "
                v-if="get(advisor, 'experience.length')"
                class="text-white"
              >
                {{ get(advisor, 'experience[0].title', 'Advisor') }}
              </h4>
            </div>
          </div>
        </div>
      </div>

      <div :class="containerClass">
        <div class="mb-4 mt-2 inner-page-container">
          <b-row>
            <b-col cols="12" md="8">
              <b-row v-if="get(advisor, 'education.length')" class="mt-4">
                <b-col
                  cols="12"
                  md="6"
                  v-for="(education, index) in advisor.education"
                  :key="`adv-edu-${index}`"
                  class="px-2"
                >
                  <b-card class="pa-3" style="border-radius: 12px">
                    <div>
                      <b>{{ ADVISOR_DEGREE_MAP[get(education, 'type', 'other')] }}, {{ get(education, 'study') }}</b>
                    </div>
                    <p class="mb-0">{{ get(education, 'institution') }}</p>
                  </b-card>
                </b-col>
              </b-row>

              <div style="font-size: 2rem; font-weight: 600">Experience</div>
              <div v-html="get(advisor, 'description')"></div>

              <div class="mt-2">
                <b-badge variant="dark" class="p-2 mr-2" v-if="get(advisor, 'program.title')">{{
                  get(advisor, 'program.title')
                }}</b-badge>
                <b-badge variant="dark" class="p-2 mr-2" v-if="get(bgByKey, `${get(advisor, 'background')}.value`)">{{
                  get(bgByKey, `${get(advisor, 'background')}.value`)
                }}</b-badge>

                <template v-if="get(advisor, 'expertise.length')">
                  <b-badge
                    variant="dark"
                    v-for="(speciality, index) in advisor.expertise"
                    :key="`adv-spec-${index}`"
                    class="p-2 mr-2"
                  >
                    {{ get(speciality, 'name') }}
                  </b-badge>
                </template>
              </div>

              <div class="mt-2 d-flex" v-if="get(advisor, 'affiliations.length')">
                <img
                  v-for="(affiliation, index) in advisor.affiliations"
                  :key="`adv-aff-${index}`"
                  :src="affiliation"
                  width="100"
                  class="mr-2"
                />
              </div>
            </b-col>

            <b-col cols="12" md="4">
              <b-card style="border-radius: 12px" class="pa-6 action-card">
                <h5 class="mb-2">
                  Interested in connecting with {{ get(advisor, 'first_name') }} {{ get(advisor, 'last_name') }}?
                </h5>
                <p>Set up a career consultation to connect with this advisor and our expansive network.</p>
                <a :href="getClickableLink(get(advisor, 'cta_link'))" target="_blank" v-if="get(advisor, 'cta_link')">
                  <b-button variant="primary" block>
                    {{ get(advisor, 'cta_text') || 'Schedule Consultation' }}
                  </b-button>
                </a>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
// import PageHeader from '@/components/Ui/PageHeader.vue';

import Page from '@/components/Page.vue';
import { mapActions, mapGetters } from 'vuex';
import { FmvAvatar } from 'fmv-avatar';
import { get, isEmpty, keyBy } from 'lodash';
// import MdIcon from '@/components/Ui/MdIcon.vue';

import { USER_ROLE_TYPES, USER_ROLES, ADVISOR_DEGREE_MAP, ADVISOR_BACKGROUND } from '@/common/constants';

// import UserAvatar from '@/components/User/UserAvatar.vue';

export default {
  components: { FmvAvatar },
  extends: Page,
  name: 'AdvisorProfile',

  data() {
    return {
      USER_ROLE_TYPES,
      USER_ROLES,
      ADVISOR_DEGREE_MAP,

      title: 'Advisor Profile',

      isLoading: true,
      advisor: {},
      bgByKey: {},
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Advisor Profile', active: true },
      ];
    },

    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
    isTabSmallScreen() {
      return this.windowWidth <= 767;
    },
  },

  methods: {
    ...mapActions('advisors', ['getAdvisor']),
    get,
    isEmpty,

    async fetchAdvisor() {
      this.isLoading = true;

      try {
        const resp = (await this.getAdvisor(this.$route.params.id)).data;

        this.advisor = resp;
      } catch (err) {
        this.$router.push({
          name: 'student-advisors',
        });
      }

      this.isLoading = false;
    },

    getClickableLink(link) {
      return link.startsWith('http://') || link.startsWith('https://') ? link : `https://${link}`;
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  async mounted() {
    this.bgByKey = keyBy(ADVISOR_BACKGROUND, 'value');
    this.fetchAdvisor();
    window.addEventListener('resize', this.handleResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
.action-card {
  @media (min-width: 768px) {
    margin-top: -90px;
  }
}
</style>
