<template>
  <b-modal ref="volExpModal" hide-footer :title="modalTitle" centered lazy size="lg" @hide="hideModal">
    <b-form @submit.prevent="onSave">
      <b-form-group label="Title" label-for="title" label-class="form-label">
        <b-form-input
          id="title"
          placeholder="Enter the job title."
          v-model="volExpData.title"
          required
          :disabled="isLoading"
        />
      </b-form-group>

      <b-form-group label="Organization" label-for="organization" label-class="form-label">
        <b-form-input
          id="organization"
          placeholder="Enter organization's name."
          v-model="volExpData.organization"
          required
          :disabled="isLoading"
        />
      </b-form-group>

      <div class="row">
        <b-form-group label="City" label-for="city" label-class="form-label" class="col-md-6">
          <b-form-input id="city" placeholder="Enter city." v-model="volExpData.city" required :disabled="isLoading" />
        </b-form-group>

        <b-form-group label="State" label-for="state" label-class="form-label" class="col-md-6">
          <b-form-input
            id="state"
            placeholder="Enter state."
            v-model="volExpData.state"
            required
            :disabled="isLoading"
          />
        </b-form-group>
      </div>

      <div class="row">
        <b-form-group label="Start Date" label-for="start_date" label-class="form-label" class="col-md-6">
          <b-form-datepicker
            v-model="volExpData.start_date"
            :max="maxDateAllowed"
            required
            :state="!$v.volExpData.start_date.required && $v.volExpData.start_date.$dirty ? false : null"
            :disabled="isLoading"
          ></b-form-datepicker>
          <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="End Date" label-for="end_date" label-class="form-label" class="col-md-6">
          <b-form-datepicker
            v-model="volExpData.end_date"
            :disabled="volExpData.still_volunteering || isLoading"
            :max="maxDateAllowed"
            required
            :state="
              (!$v.volExpData.end_date.required || !$v.volExpData.end_date.isCorrectFormat) &&
              $v.volExpData.end_date.$dirty
                ? false
                : null
            "
          ></b-form-datepicker>
          <b-form-checkbox
            id="still_volunteering"
            v-model="volExpData.still_volunteering"
            name="still_volunteering"
            class="mt-1"
            :disabled="isLoading"
          >
            I currently volunteer here.
          </b-form-checkbox>
          <b-form-invalid-feedback v-if="!$v.volExpData.end_date.required"
            >This field is required.</b-form-invalid-feedback
          >
          <b-form-invalid-feedback v-else>End date must be greater than the start date.</b-form-invalid-feedback>
        </b-form-group>
      </div>

      <div class="mb-2">
        <page-separator title="Volunteer Tasks" />
        <div class="d-flex justify-content-end">
          <b-btn variant="primary" @click.prevent="addVolTask" size="sm" :disabled="isLoading">
            <span>Add Task</span>
          </b-btn>
        </div>

        <i v-if="!get(volExpData, 'volunteer_tasks.length')">No volunteer tasks added.</i>

        <div v-for="(task, index) in volExpData.volunteer_tasks" :key="`task-${index}`">
          <div class="d-flex justify-content-between">
            <div class="w-100">
              <b-form-group label="Task" :label-for="`task-${index}`" label-class="form-label">
                <b-form-input
                  :id="`task-${index}`"
                  placeholder="Enter task info."
                  v-model="volExpData.volunteer_tasks[index]"
                  required
                  :disabled="isLoading"
                />
              </b-form-group>
            </div>

            <div class="d-flex align-items-center ml-2">
              <b-btn variant="danger" :disabled="isLoading" @click.prevent="removeTask(index)" size="sm">
                <span>Remove</span>
              </b-btn>
            </div>
          </div>
        </div>
      </div>

      <b-btn
        variant="primary"
        style="width: 200px"
        type="submit"
        class="btn-normal d-flex ml-auto"
        :disabled="isLoading"
      >
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else> {{ volExp ? 'Update' : 'Add' }} Experience</span>
      </b-btn>
    </b-form>
  </b-modal>
</template>

<script>
import { get, pick } from 'lodash';
import moment from 'moment';
import { required, requiredIf } from 'vuelidate/lib/validators';

import DefaultImg from '@/assets/images/placeholders/cna_career.jpg';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

export default {
  name: 'VolunteerExpModal',

  components: { PageSeparator },

  props: {
    showModal: { type: Boolean, default: false },
    volExp: { type: Object || null },
    expIndex: { type: Number || null },
  },

  data() {
    return {
      DefaultImg,

      volExpData: {
        title: '',
        organization: '',
        city: '',
        state: '',
        start_date: '',
        end_date: '',
        still_volunteering: false,
        volunteer_tasks: [],
      },

      modalTitle: '',
      maxDateAllowed: undefined,

      isLoading: false,
    };
  },

  validations() {
    return {
      volExpData: {
        title: { required },
        organization: { required },
        city: { required },
        state: { required },
        start_date: { required },
        end_date: {
          required: requiredIf(() => !this.volExpData.still_volunteering),
          isCorrectFormat(value) {
            return (
              this.volExpData.still_volunteering ||
              !this.volExpData.start_date ||
              !this.volExpData.end_date ||
              moment(this.volExpData.start_date).isBefore(moment(value))
            );
          },
        },
        still_volunteering: {},
        volunteer_tasks: {},
      },
    };
  },

  computed: {},

  watch: {
    showModal(value) {
      if (value) {
        this.modalTitle = this.volExp ? 'Edit Volunteer Experience' : 'Add Volunteer Experience';
        this.maxDateAllowed = new Date();
        if (this.volExp) {
          this.volExpData = pick(this.volExp, [
            'title',
            'organization',
            'city',
            'state',
            'start_date',
            'end_date',
            'still_volunteering',
            'volunteer_tasks',
          ]);
        } else {
          this.volExpData = {
            title: '',
            organization: '',
            city: '',
            state: '',
            start_date: '',
            end_date: '',
            still_volunteering: false,
            volunteer_tasks: [''],
          };
        }
        this.$v.$reset();
        this.$refs.volExpModal.show();
      }
    },
  },

  methods: {
    get,

    hideModal() {
      this.$emit('close');
    },

    hideUpdateModal(data) {
      this.$refs.volExpModal.hide();
      this.$emit('closeUpdate', data);
    },

    hideModalManual() {
      this.$refs.volExpModal.hide();
      this.hideModal();
    },

    onSave() {
      this.isLoading = true;

      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.hideUpdateModal({
          volExp: {
            ...this.volExpData,
            end_date: this.volExpData.still_volunteering ? null : this.volExpData.end_date,
          },
          index: this.expIndex,
          isUpdate: !!this.volExp,
        });
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }

      this.isLoading = false;
    },

    addVolTask() {
      this.volExpData.volunteer_tasks.push('');
    },

    removeTask(index) {
      this.volExpData.volunteer_tasks.splice(index, 1);
    },
  },

  async mounted() {},
};
</script>
