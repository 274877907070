<template>
  <fmv-sidebar type="light" variant="white" class="py-16pt" wheel-propagation>
    <div class="sidebar-heading mb-4" style="font-size: 18px; font-weight: bolder">Filters</div>

    <div class="sidebar-heading">Program</div>
    <div class="sidebar-block">
      <b-form-group>
        <v-select
          id="offered_programs"
          class="form-control v-select-custom"
          label="title"
          v-model="filters.program"
          :reduce="offered_programs => offered_programs.id"
          placeholder="Filter program"
          :options="allPrograms"
          :loading="areProgramsLoading"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" v-bind="attributes" v-on="events" />
          </template>
          <template slot="option" slot-scope="option">
            <span>{{ option.title }}</span>
          </template>
        </v-select>
      </b-form-group>
    </div>

    <div class="sidebar-heading">Background</div>

    <div class="sidebar-block">
      <v-select
        id="background"
        class="form-control v-select-custom"
        label="text"
        v-model="filters.background"
        :reduce="item => item.value"
        placeholder="Filter background"
        :options="backgroundOptions"
      >
      </v-select>
    </div>
  </fmv-sidebar>
</template>

<script>
import { FmvSidebar } from 'fmv-layout';
import { truncate, get } from 'lodash';
import { mapActions } from 'vuex';
import { ADVISOR_BACKGROUND } from '@/common/constants';

export default {
  name: 'FiltersSidebar',
  components: { FmvSidebar },

  data() {
    return {
      filters: {
        program: null,
        background: null,
      },
      allPrograms: [],
      areProgramsLoading: false,
      backgroundOptions: ADVISOR_BACKGROUND,
    };
  },

  methods: {
    get,
    truncate,
    ...mapActions('program', ['getAllPublicPrograms']),
    ...mapActions('advisors', ['updateAdvisorFilters']),

    async fetchPrograms() {
      this.areProgramsLoading = true;

      const response = await this.getAllPublicPrograms();
      this.allPrograms = response.data;
      this.areProgramsLoading = false;
    },
  },

  watch: {
    filters: {
      deep: true,
      handler(filters) {
        this.updateAdvisorFilters({ filters });
      },
    },
  },
  async mounted() {
    this.fetchPrograms();
  },
};
</script>
