<template>
  <div class="page-section">
    <div :class="containerClass">
      <h2 class="text-center mb-2">My Info</h2>
      <p
        class="text-center mb-4"
        v-if="
          getLoggedInUserDashboard === DASHBOARD_TYPES.CARECAMPUS ||
            getLoggedInUserDashboard === DASHBOARD_TYPES.ORGANIZATION
        "
      >
        Manage and showcase your career readiness by tracking your progress with these tools.
      </p>
      <p class="text-center mb-4" v-else>
        View and manage your Questions, Skills, Documents, Payments, Resume and Sponsorship Applications on my info.
      </p>
      <div class="row mb-5">
        <template v-for="info in myInfo">
          <DashboardCard :key="info.id" :guide="info" color="primary" class="col-md-6 mb-2" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapGetters } from 'vuex';
import Page from '@/components/Page.vue';
import { DASHBOARD_TYPES } from '@/common/constants';
import DashboardCard from '@/components/DashboardCard.vue';
import { mapActions } from 'vuex';

export default {
  extends: Page,
  components: { DashboardCard },
  data() {
    return {
      title: 'My Info',
      windowWidth: window.innerWidth,
      DASHBOARD_TYPES,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser', 'getLoggedInUserDashboard']),
    ...mapGetters('school', ['getInAppPaymentStatus', 'getPaymentStatus']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Setup Guide', active: true },
      ];
    },
    isMobileScreen() {
      return this.windowWidth <= 600;
    },
    myInfo() {
      return [
        ...(this.getLoggedInUserDashboard === DASHBOARD_TYPES.SCHOOL
          ? [
              // {
              //   id: 5,
              //   title: 'My Plans',
              //   description: `View and manage your upcoming dues`,
              //   redirectTo: 'manage-plans',
              //   icon: 'attach_money',
              //   btnText: 'Find',
              // },
              {
                id: 1,
                title: 'My Uploads',
                description: 'View and manage your uploaded documents and credentials.',
                abbr: 'uploaded_docs',
                redirectTo: 'std-manage-records',
                icon: 'drive_folder_upload',
                btnText: 'Complete',
              },
              {
                id: 2,
                title: 'My Payments',
                description: `Track and organize your payment history.`,
                abbr: 'payments_paid',
                redirectTo: 'std-payment-list',
                icon: 'payment',
                btnText: 'Complete',
              },
              {
                id: 4,
                title: 'My Applications',
                description: 'Monitor the status of your submitted sponsorship applications.',
                redirectTo: 'std-manage-applications',
                icon: 'assignment',
                btnText: 'Search',
              },
            ]
          : []),
        {
          id: 3,
          title: 'My Resume',
          description: 'View and update your custom resume for employment.',
          abbr: 'resume_build',
          redirectTo: 'std-manage-resume',
          icon: 'description',
          btnText: 'Complete',
        },
        {
          id: 8,
          title: 'My Simulations',
          description:
            'Review your submitted interview simulations to track your progress and prepare for potential recruiters.',
          redirectTo: 'student-submissions-listing',
          icon: 'mic',
          btnText: 'Search',
        },
        {
          title: 'My Certifications',
          abbr: 'add_certifications',
          btnText: 'Complete',
          description: 'Showcase your certifications to demonstrate your skills and expertise.',
          enable: true,
          icon: 'assignment_turned_in',
          redirectTo: 'my-certifications',
        },
        ...(this.getLoggedInUserDashboard === DASHBOARD_TYPES.ORGANIZATION
          ? [
              {
                title: 'My Offers',
                abbr: 'add_new_offers',
                btnText: 'Complete',
                description: 'Highlight past job offers to showcase your career growth and industry demand.',
                enable: true,
                icon: 'payment',
                redirectTo: 'my-offers',
              },
            ]
          : []),
        ...(this.getLoggedInUserDashboard === DASHBOARD_TYPES.CARECAMPUS
          ? [
              {
                title: 'My Courses',
                abbr: 'add_new_courses',
                btnText: 'Complete',
                description: 'Highlight the courses you enrolled to showcase your career growth.',
                enable: true,
                icon: 'local_library',
                redirectTo: 'my-courses',
              },
            ]
          : []),
        ...(this.getInAppPaymentStatus && this.getLoggedInUserDashboard === DASHBOARD_TYPES.SCHOOL
          ? [
              {
                id: 5,
                title: 'My Plans',
                description: `View and manage your upcoming dues`,
                redirectTo: 'manage-plans',
                icon: 'attach_money',
                btnText: 'Find',
              },
            ]
          : []),
        ...(this.getPaymentStatus && this.getLoggedInUserDashboard === DASHBOARD_TYPES.SCHOOL
          ? [
              {
                id: 6,
                title: 'My Skills',
                description: `View and manage skills for your career profile.`,
                redirectTo: 'my-skills',
                icon: 'videocam',
                btnText: 'Find',
              },
              {
                id: 7,
                title: 'My Questions',
                description: `Review your inquiries and responses for continuous learning.`,
                redirectTo: 'my-questions',
                icon: 'help',
                btnText: 'Find',
              },
              {
                id: 9,
                title: 'My Attendance',
                description: `Review your inquiries and responses for continuous learning.`,
                redirectTo: 'student-attendance',
                icon: 'calendar_today',
                btnText: 'Find',
              },
              {
                id: 12,
                title: 'My Transcript',
                description: 'Download your transcript for the registered course.',
                icon: 'receipt',
                btnText: 'Transcript',
                btnIcon: 'file_download',
                handleTranscriptDownload: () => {
                  this.handleTranscriptDownload();
                },
              },
            ]
          : []),
      ];
    },
  },

  methods: {
    ...mapActions('student', ['getStudTranscript']),
    ...mapActions('fileDownload', ['downloadFile']),
    get,
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    async handleTranscriptDownload() {
      try {
        const user = this.getLoggedInUser;
        const resp = await this.getStudTranscript({ id: user.id });
        const h = this.$createElement;
        const url = resp.data.data.url;
        if (url) {
          await this.downloadFile({ fileUrl: resp.data.data.url });

          this.makeToast({
            variant: 'success',
            msg: h('span', [h('i', { class: 'fas  fa-download mr-2' }), 'File Downloaded']),
            toaster: 'b-toaster-bottom-left',
          });
        } else {
          this.makeToast({
            variant: 'danger',
            msg: h('span', ['Transcript Not Found']),
            toaster: 'b-toaster-top-right',
          });
        }
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
  },
  async mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
