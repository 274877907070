<template>
  <div :class="containerClass">
    <!-- <page-header title="Resume" :breadcrumb="breadcrumb" :container-class="null" class="mb-32pt" /> -->

    <div class="d-flex flex-column flex-sm-row align-items-sm-center mb-24pt" style="white-space: nowrap">
      <h2 class="mb-0">
        My Resume

        <i class="material-icons icon-16pt clickable-item" v-b-popover.hover.top="`View and edit your resume.`">info</i>
      </h2>
      <div class="w-auto ml-sm-auto mb-sm-0 justify-content-end">
        <b-btn
          variant="secondary"
          style="width: 190px"
          type="submit"
          class="ml-2 btn-normal"
          @click.prevent="downloadPdf"
          :disabled="isResDownloading"
        >
          <i v-if="isResDownloading" class="fas fa-circle-notch fa-spin"></i>
          <span v-else><i class="material-icons mr-1">file_download</i>Download Resume</span>
        </b-btn>
      </div>
    </div>

    <page-separator title="" />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <!-- About -->

      <div class="card">
        <div class="card-header">
          <h5 class="my-0">About You</h5>
        </div>

        <div class="card-body">
          <div class="row">
            <b-form-group
              :label="$t('userMsgs.firstName')"
              label-for="firstname"
              label-class="form-label"
              class="col-md-6"
            >
              <b-form-input id="firstname" :placeholder="$t('userMsgs.firstName')" v-model="user.firstName" required />
            </b-form-group>

            <b-form-group
              :label="$t('userMsgs.lastName')"
              label-for="lastname"
              label-class="form-label"
              class="col-md-6"
            >
              <b-form-input id="lastname" :placeholder="$t('userMsgs.lastName')" v-model="user.lastName" required />
            </b-form-group>
          </div>

          <b-form-group :label="$t('authMsgs.emailAddr')" label-for="email" label-class="form-label">
            <b-form-input
              id="email"
              :placeholder="$t('authMsgs.emailAddr')"
              :value="user.email"
              type="email"
              autocomplete="off"
              disabled
            />
          </b-form-group>

          <b-form-group :label="$t('studentMsgs.phone')" label-for="phone" label-class="form-label">
            <b-form-input
              id="phone"
              placeholder="(888) 689 - 1235"
              v-model="user.phone"
              v-mask="'(###) ### - ####'"
              :state="!$v.user.phone.isCorrectFormat && $v.user.phone.$dirty ? false : null"
              required
            />
            <b-form-invalid-feedback>
              Invalid phone number. Please make sure that it's in correct format i.e. (XXX) XXX - XXXX.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Resume Title" label-for="resume_title" label-class="form-label">
            <b-form-input
              id="resume_title"
              placeholder="Enter the title you want to be displayed on the resume."
              v-model="student.resume_title"
              required
            />
          </b-form-group>

          <b-form-group label="Resume About" label-for="resume_about" label-class="form-label">
            <b-form-textarea
              id="resume_about"
              placeholder="Enter a description about you that you wish to be displayed on the resume."
              :rows="8"
              v-model="student.resume_about"
              required
            />
          </b-form-group>
        </div>
      </div>

      <!-- Address Fields -->

      <div class="card">
        <div class="card-header">
          <h5 class="my-0">Location</h5>
        </div>

        <div class="card-body">
          <b-form-group label="Street Address" label-for="address" label-class="form-label">
            <b-form-input id="address" placeholder="Street Address" v-model="student.address" required />
          </b-form-group>

          <div class="row">
            <b-form-group
              :label="$t('addressMsgs.zipcode')"
              label-for="zipcode"
              label-class="form-label"
              class="col-md-6"
            >
              <b-form-input id="zipcode" :placeholder="$t('addressMsgs.zipcode')" v-model="student.zipcode" required />
            </b-form-group>

            <b-form-group :label="$t('addressMsgs.city')" label-for="city" label-class="form-label" class="col-md-6">
              <b-form-input id="city" :placeholder="$t('addressMsgs.city')" v-model="student.city" required />
            </b-form-group>
          </div>

          <div class="row">
            <b-form-group :label="$t('addressMsgs.state')" label-for="state" label-class="form-label" class="col-md-6">
              <v-select
                id="state"
                class="form-control v-select-custom"
                label="text"
                v-model="student.state"
                :reduce="state => state.value"
                placeholder="Select State"
                :options="stateOptions"
              >
                <template #search="{ attributes, events }">
                  <input class="vs__search" :required="!student.state" v-bind="attributes" v-on="events" />
                </template>
              </v-select>
            </b-form-group>

            <b-form-group
              :label="$t('addressMsgs.country')"
              label-for="country"
              label-class="form-label"
              class="col-md-6"
            >
              <v-select
                id="country"
                class="form-control v-select-custom"
                label="text"
                disabled
                v-model="student.country"
                :reduce="country => country.value"
                :placeholder="$t('addressMsgs.countryPlaceholder')"
                :options="countryOptions"
              >
                <template #search="{ attributes, events }">
                  <input class="vs__search" :required="!student.country" v-bind="attributes" v-on="events" />
                </template>
              </v-select>
            </b-form-group>
          </div>
        </div>
      </div>

      <!-- Education Related Fields -->

      <div class="card">
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <h5 class="my-0">Education</h5>
            <b-btn variant="primary" :disabled="isLoading" @click.prevent="openEducationModal()" size="sm">
              <span>
                <i class="fas fa-plus align-self-center" :class="{ 'mx-3': isTabSmallScreen }"></i>
                <strong class="ml-1" v-if="!isTabSmallScreen">Add Education</strong>
              </span>
            </b-btn>
          </div>
        </div>

        <div class="card-body">
          <div v-for="(education, index) in student.educations" :key="`edu-${index}`">
            <div class="mb-3 align-items-center" :class="{ 'd-flex justify-content-between': !isMobileScreen }">
              <div>
                <h6 class="mb-0 pb-0">{{ education.study }}</h6>
                <div>
                  <i class="material-icons icon-16pt mr-1">account_balance</i>
                  <strong>{{ education.institution }}</strong>
                </div>
                <div>
                  {{ $t(`resumeEducationTypes.${education.type}`) }}
                </div>
                <div>
                  {{ formatExpDate(education.start_date) }} to
                  {{ education.still_attending ? 'Present' : formatExpDate(education.end_date) }}, {{ education.city }},
                  {{ education.state }}
                </div>
              </div>
              <div :class="{ 'd-flex justify-content-end mt-2': isMobileScreen }">
                <b-btn
                  variant="secondary"
                  :disabled="isLoading"
                  @click.prevent="openEducationModal(education, index)"
                  size="sm"
                  class="mr-1"
                >
                  <span>
                    <i class="fas fa-pen align-self-center" :class="{ 'mx-1': isTabSmallScreen }"></i>
                    <strong class="ml-1" v-if="!isTabSmallScreen">Edit</strong>
                  </span>
                </b-btn>
                <b-btn
                  variant="danger"
                  :disabled="isLoading"
                  @click.prevent="removeExpEduItem(index, 'education', 'educations')"
                  size="sm"
                >
                  <span>
                    <i class="fas fa-trash align-self-center" :class="{ 'mx-1': isTabSmallScreen }"></i>
                    <strong class="ml-1" v-if="!isTabSmallScreen">Remove</strong>
                  </span>
                </b-btn>
              </div>
            </div>
          </div>

          <i v-if="!get(student, 'educations.length')">There is no education history present.</i>
        </div>
      </div>

      <!-- Work Experience -->
      <div class="card">
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <h5 class="my-0">Work Experience</h5>
            <b-btn variant="primary" :disabled="isLoading" @click.prevent="openWorkExpModal()" size="sm">
              <span>
                <i class="fas fa-plus align-self-center" :class="{ 'mx-3': isTabSmallScreen }"></i>
                <strong class="ml-1" v-if="!isTabSmallScreen">Add Experience</strong>
              </span>
            </b-btn>
          </div>
        </div>

        <div class="card-body">
          <div v-for="(exp, index) in student.work_experiences" :key="`exp-${index}`">
            <div class="mb-3 align-items-center" :class="{ 'd-flex justify-content-between': !isMobileScreen }">
              <div>
                <h6 class="mb-0 pb-0">{{ exp.title }}</h6>
                <div>
                  <i class="material-icons icon-16pt mr-1">business</i>
                  <strong>{{ exp.employer }}</strong>
                </div>
                <div>
                  {{ formatExpDate(exp.start_date) }} to
                  {{ exp.still_working ? 'Present' : formatExpDate(exp.end_date) }}, {{ exp.city }}, {{ exp.state }}
                </div>
                <div v-for="(task, index) in exp.work_tasks" :key="`exp-task-${index}`">
                  <i class="material-icons icon-16pt mr-1">check_circle</i>{{ task }}
                </div>
              </div>
              <div :class="{ 'd-flex justify-content-end mt-2': isMobileScreen }">
                <b-btn
                  variant="secondary"
                  :disabled="isLoading"
                  @click.prevent="openWorkExpModal(exp, index)"
                  size="sm"
                  class="mr-1"
                >
                  <span>
                    <i class="fas fa-pen align-self-center" :class="{ 'mx-1': isTabSmallScreen }"></i>
                    <strong class="ml-1" v-if="!isTabSmallScreen">Edit</strong>
                  </span>
                </b-btn>
                <b-btn
                  variant="danger"
                  :disabled="isLoading"
                  @click.prevent="removeExpEduItem(index, 'experience', 'work_experiences')"
                  size="sm"
                >
                  <span>
                    <i class="fas fa-trash align-self-center" :class="{ 'mx-1': isTabSmallScreen }"></i>
                    <strong class="ml-1" v-if="!isTabSmallScreen">Remove</strong>
                  </span>
                </b-btn>
              </div>
            </div>
          </div>
          <i v-if="!get(student, 'work_experiences.length')">There are no work experiences added.</i>
        </div>
      </div>

      <!-- Volunteer Experience -->
      <div class="card">
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <h5 class="my-0">Volunteer Experience</h5>
            <b-btn variant="primary" :disabled="isLoading" @click.prevent="openVolunteerExpModal()" size="sm">
              <span>
                <i class="fas fa-plus align-self-center" :class="{ 'mx-3': isTabSmallScreen }"></i>
                <strong class="ml-1" v-if="!isTabSmallScreen">Add Experience</strong>
              </span>
            </b-btn>
          </div>
        </div>

        <div class="card-body">
          <div v-for="(vol, index) in student.volunteer_experiences" :key="`exp-${index}`">
            <div class="mb-3 align-items-center" :class="{ 'd-flex justify-content-between': !isMobileScreen }">
              <div>
                <h6 class="mb-0 pb-0">{{ vol.title }}</h6>
                <div>
                  <i class="material-icons icon-16pt mr-1">business</i>
                  <strong>{{ vol.organization }}</strong>
                </div>
                <div>
                  {{ formatExpDate(vol.start_date) }} to
                  {{ vol.still_volunteering ? 'Present' : formatExpDate(vol.end_date) }}, {{ vol.city }},
                  {{ vol.state }}
                </div>
                <div v-for="(task, index) in vol.volunteer_tasks" :key="`exp-task-${index}`">
                  <i class="material-icons icon-16pt mr-1">check_circle</i>{{ task }}
                </div>
              </div>
              <div :class="{ 'd-flex justify-content-end mt-2': isMobileScreen }">
                <b-btn
                  variant="secondary"
                  :disabled="isLoading"
                  @click.prevent="openVolunteerExpModal(vol, index)"
                  size="sm"
                  class="mr-1"
                >
                  <span>
                    <i class="fas fa-pen align-self-center" :class="{ 'mx-1': isTabSmallScreen }"></i>
                    <strong class="ml-1" v-if="!isTabSmallScreen">Edit</strong>
                  </span>
                </b-btn>
                <b-btn
                  variant="danger"
                  :disabled="isLoading"
                  @click.prevent="removeExpEduItem(index, 'volunteer experience', 'volunteer_experiences')"
                  size="sm"
                >
                  <span>
                    <i class="fas fa-trash align-self-center" :class="{ 'mx-1': isTabSmallScreen }"></i>
                    <strong class="ml-1" v-if="!isTabSmallScreen">Remove</strong>
                  </span>
                </b-btn>
              </div>
            </div>
          </div>
          <i v-if="!get(student, 'volunteer_experiences.length')">There is no volunteer experience added.</i>
        </div>
      </div>

      <!-- Skills -->

      <div class="card">
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <h5 class="my-0">Skills</h5>
            <b-btn variant="primary" :disabled="isLoading" @click.prevent="openSkillModal()" size="sm">
              <span>
                <i class="fas fa-plus align-self-center" :class="{ 'mx-3': isTabSmallScreen }"></i>
                <strong class="ml-1" v-if="!isTabSmallScreen">Add Skills</strong>
              </span>
            </b-btn>
          </div>
        </div>

        <div class="card-body">
          <div v-for="(skill, index) in student.skills" :key="`skill-${index}`">
            <div class="mb-3 align-items-center" :class="{ 'd-flex justify-content-between': !isMobileScreen }">
              <div>
                <div>
                  <i class="material-icons icon-16pt mr-1">check_circle</i>
                  <strong>{{ skill.name === 'other' ? skill.other_skill : skill.name }}</strong>
                </div>
              </div>

              <div :class="{ 'd-flex justify-content-end mt-2': isMobileScreen }">
                <b-btn
                  variant="secondary"
                  :disabled="isLoading"
                  @click.prevent="openSkillModal(skill, index)"
                  size="sm"
                  class="mr-1"
                >
                  <span>
                    <i class="fas fa-pen align-self-center" :class="{ 'mx-1': isTabSmallScreen }"></i>
                    <strong class="ml-1" v-if="!isTabSmallScreen">Edit</strong>
                  </span>
                </b-btn>
                <b-btn
                  variant="danger"
                  :disabled="isLoading"
                  @click.prevent="removeExpEduItem(index, 'skill', 'skills')"
                  size="sm"
                >
                  <span>
                    <i class="fas fa-trash align-self-center" :class="{ 'mx-1': isTabSmallScreen }"></i>
                    <strong class="ml-1" v-if="!isTabSmallScreen">Remove</strong>
                  </span>
                </b-btn>
              </div>
            </div>
          </div>

          <i v-if="!get(student, 'skills.length')">There are no skills added.</i>
        </div>
      </div>

      <div class="d-flex">
        <b-btn variant="primary" :disabled="isFormLoading" style="width: 200px" type="submit" class="btn-normal">
          <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
          <span v-else>Save</span>
        </b-btn>
      </div>
    </b-form>

    <work-exp-modal
      :show-modal="modals.showWorkExpModal"
      :work-exp="modals.selectedWorkExp"
      :exp-index="modals.selectedWorkExpIndex"
      @close="hideWorkExpModal"
      @closeUpdate="hideUpdateWorkExpModal"
    >
    </work-exp-modal>
    <volunteer-exp-modal
      :show-modal="modals.showVolunteerExpModal"
      :vol-exp="modals.selectedVolunteerExp"
      :exp-index="modals.selectedVolunteerExpIndex"
      @close="hideVolExpModal"
      @closeUpdate="hideUpdateVolExpModal"
    >
    </volunteer-exp-modal>
    <resume-education-modal
      :show-modal="modals.showEducationModal"
      :education="modals.selectedEducation"
      :item-index="modals.selectedEduIndex"
      @close="hideEducationModal"
      @closeUpdate="hideUpdateEducationModal"
    >
    </resume-education-modal>
    <resume-skill-modal
      :show-modal="modals.showSkillModal"
      :skill="modals.selectedSkill"
      :item-index="modals.selectedSkillIndex"
      :added-skills="student.skills"
      :all-skills="allSkills"
      @close="hideSkillModal"
      @closeUpdate="hideUpdateSkillModal"
    >
    </resume-skill-modal>
  </div>
</template>

<script>
import { countries } from 'countries-list';
import { get, map, orderBy, pick } from 'lodash';
import { mapActions } from 'vuex';
// import PageHeader from '@/components/Ui/PageHeader.vue';
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import Page from '../../../components/Page.vue';
import { PHONE_FORMAT_REGEX, DEFAULT_RESUME_TITLE, DEFAULT_RESUME_ABOUT } from '../../../common/constants';
import { parseFileNameFromUrl, getLangOptions } from '../../../common/utils';
import WorkExpModal from './DataModals/WorkExpModal.vue';
import ResumeEducationModal from './DataModals/ResumeEducationModal.vue';
import ResumeSkillModal from './DataModals/ResumeSkillModal.vue';
import VolunteerExpModal from './DataModals/VolunteerExpModal.vue';
var UsaStates = require('usa-states').UsaStates;

export default {
  name: 'ManageResume',
  extends: Page,
  components: { WorkExpModal, VolunteerExpModal, ResumeEducationModal, ResumeSkillModal, PageSeparator },

  data() {
    return {
      title: 'Resume',

      allSkills: [],
      user: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
      },
      student: {
        address: '',
        zipcode: '',
        city: '',
        state: '',
        country: 'United States',
        work_experiences: [],
        volunteer_experiences: [],
        educations: [],
        skills: [],
        resume_title: '',
        resume_about: '',
      },

      countryOptions: [],
      stateOptions: [],

      isLoading: false,
      isResDownloading: false,
      windowWidth: window.innerWidth,
      modals: {
        showVolunteerExpModal: false,
        selectedVolunteerExp: null,
        selectedVolunteerExpIndex: null,

        showWorkExpModal: false,
        selectedWorkExp: null,
        selectedWorkExpIndex: null,

        showEducationModal: false,
        selectedEducation: null,
        selectedEduIndex: null,

        showSkillModal: false,
        selectedSkill: null,
        selectedSkillIndex: null,
      },
    };
  },

  validations() {
    return {
      student: {
        address: { required },
        zipcode: { required },
        city: { required },
        state: { required },
        country: { required },
        work_experiences: {},
        volunteer_experiences: {},
        educations: {},
        skills: {},
        resume_title: { required },
        resume_about: { required },
      },
      user: {
        firstName: { required },
        lastName: { required },
        email: { required },
        phone: {
          required,
          isCorrectFormat(value) {
            return !value || PHONE_FORMAT_REGEX.test(value);
          },
        },
      },
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Resume', active: true },
      ];
    },
    isFormLoading() {
      return this.isLoading || this.isResDownloading;
    },

    isMobileScreen() {
      return this.windowWidth <= 420;
    },

    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
    isTabSmallScreen() {
      return this.windowWidth <= 767;
    },
  },

  methods: {
    ...mapActions('student', [
      'getMyResumeProfile',
      'updateMyResumeProfile',
      'getStudentUploadPresignedUrl',
      'getResumePdfUrl',
      'getProgramSkills',
      'updateShowEarnedPointToast',
    ]),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('fileDownload', ['downloadFile']),
    parseFileNameFromUrl,
    get,

    async downloadPdf() {
      this.isResDownloading = true;

      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          await this.updateMyResumeProfile({
            data: {
              ...this.student,
              user: {
                first_name: this.user.firstName,
                last_name: this.user.lastName,
                phone: this.user.phone,
                avatar_url: this.user.avatarUrl,
                gender: this.user.gender,
              },
            },
          });
          const resp = await this.getResumePdfUrl();
          await this.downloadFile({ fileUrl: resp.file_url, removeTimestamp: true });

          const h = this.$createElement;
          this.makeToast({
            variant: 'success',
            msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'File Downloaded']),
            toaster: 'b-toaster-bottom-left',
          });
        } catch (_err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }

      this.isResDownloading = false;
    },

    openWorkExpModal(workExp = null, index = null) {
      this.modals.selectedWorkExp = workExp;
      this.modals.selectedWorkExpIndex = index;
      this.modals.showWorkExpModal = true;
    },
    hideWorkExpModal() {
      this.modals.showWorkExpModal = false;
      this.modals.selectedWorkExp = null;
      this.modals.selectedWorkExpIndex = null;
    },
    hideUpdateWorkExpModal(data) {
      this.modals.showWorkExpModal = false;
      this.modals.selectedWorkExp = null;
      this.modals.selectedWorkExpIndex = null;

      if (data.isUpdate) {
        this.student.work_experiences[data.index] = data.workExp;
      } else {
        this.student.work_experiences.push(data.workExp);
      }
      this.student.work_experiences = orderBy(
        this.student.work_experiences,
        ['still_working', 'start_date'],
        ['desc', 'desc'],
      );
    },
    openVolunteerExpModal(volunteerExp = null, index = null) {
      this.modals.selectedVolunteerExp = volunteerExp;
      this.modals.selectedVolunteerExpIndex = index;
      this.modals.showVolunteerExpModal = true;
    },
    hideVolExpModal() {
      this.modals.showVolunteerExpModal = false;
      this.modals.selectedVolunteerExp = null;
      this.modals.selectedVolunteerExpIndex = null;
    },
    hideUpdateVolExpModal(data) {
      this.modals.showVolunteerExpModal = false;
      this.modals.selectedVolunteerExp = null;
      this.modals.selectedVolunteerExpIndex = null;
      if (data.isUpdate) {
        this.student.volunteer_experiences[data.index] = data.volExp;
      } else {
        this.student.volunteer_experiences.push(data.volExp);
      }
      this.student.volunteer_experiences = orderBy(
        this.student.volunteer_experiences,
        ['still_volunteering', 'start_date'],
        ['desc', 'desc'],
      );
    },
    openEducationModal(education = null, index = null) {
      this.modals.selectedEducation = education;
      this.modals.selectedEduIndex = index;
      this.modals.showEducationModal = true;
    },
    hideEducationModal() {
      this.modals.showEducationModal = false;
      this.modals.selectedEducation = null;
      this.modals.selectedEduIndex = null;
    },
    hideUpdateEducationModal(data) {
      this.modals.showEducationModal = false;
      this.modals.selectedEducation = null;
      this.modals.selectedEduIndex = null;

      if (data.isUpdate) {
        this.student.educations[data.index] = data.education;
      } else {
        this.student.educations.push(data.education);
      }
      this.student.educations = orderBy(this.student.educations, ['still_attending', 'start_date'], ['desc', 'desc']);
    },

    openSkillModal(education = null, index = null) {
      this.modals.selectedSkill = education;
      this.modals.selectedSkillIndex = index;
      this.modals.showSkillModal = true;
    },
    hideSkillModal() {
      this.modals.showSkillModal = false;
      this.modals.selectedSkill = null;
      this.modals.selectedSkillIndex = null;
    },
    hideUpdateSkillModal(data) {
      this.modals.showSkillModal = false;
      this.modals.selectedSkill = null;
      this.modals.selectedSkillIndex = null;

      if (data.isUpdate) {
        this.student.skills[data.index] = data.skill;
      } else {
        this.student.skills.push(data.skill);
      }
    },

    async removeExpEduItem(index, type, itemKey) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to delete the selected ${type}.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          },
        );
        if (isConfirmed) {
          this.student[itemKey].splice(index, 1);
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },

    async onSubmit() {
      this.isLoading = true;

      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          const resumeResp = await this.updateMyResumeProfile({
            data: {
              ...this.student,
              user: {
                first_name: this.user.firstName,
                last_name: this.user.lastName,
                phone: this.user.phone,
                avatar_url: this.user.avatarUrl,
                gender: this.user.gender,
              },
            },
          });
          if (resumeResp.points_earned > 0) {
            this.updateShowEarnedPointToast(resumeResp.points_earned);
          }
          this.makeToast({ variant: 'success', msg: 'Profile Successfully Updated' });
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }

      this.isLoading = false;
    },

    fillInOptions() {
      var usStates = new UsaStates();
      this.stateOptions = this.stateOptions.concat(
        map(usStates.states, state => ({
          value: state.name,
          text: state.name,
        })),
      );
      this.countryOptions = this.countryOptions.concat(
        map(countries, country => ({
          value: country.name,
          text: country.name,
        })),
      );

      this.workDayOptions = map(moment.weekdays(), day => ({ value: day.toLowerCase(), text: day }));

      this.workTravelOptions = map([...Array(9).keys()], num => ({
        value: `${(num + 1) * 10}`,
        text: `${(num + 1) * 10} miles`,
      }));
      this.workTravelOptions.push({ value: '100+', text: '100+ miles' });

      this.langOptions = getLangOptions();
    },
    async fetchSkills() {
      this.isLoading = true;
      const resp = await this.getProgramSkills({});
      for (let i = 0; i < resp.skills.length; i++) {
        this.allSkills.push({
          value: resp.skills[i],
          text: resp.skills[i],
        });
      }
      this.allSkills.push({ value: 'other', text: 'Other' });
      this.isLoading = false;
    },
    async fetchStudentProfile() {
      this.isLoading = true;
      const resp = await this.getMyResumeProfile(true);
      this.student = {
        ...pick(resp, ['address', 'zipcode', 'city', 'state', 'country', 'resume_about', 'resume_title']),
        work_experiences: get(resp, 'work_experiences', []),
        volunteer_experiences: get(resp, 'volunteer_experiences', []),
        educations: get(resp, 'educations', []),
        skills: get(resp, 'skills', []),
        resume_title: resp.resume_title || DEFAULT_RESUME_TITLE,
        resume_about: resp.resume_about || DEFAULT_RESUME_ABOUT,
      };
      this.user.firstName = resp.user.first_name;
      this.user.lastName = resp.user.last_name;
      this.user.email = resp.user.email;
      this.user.phone = resp.user.phone;
      this.isLoading = false;
    },

    formatExpDate(date) {
      return moment(date).format('MMMM, YYYY');
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  async mounted() {
    if (this.$route.params.workExperienceModal) {
      this.openWorkExpModal();
    }
    this.fillInOptions();
    this.fetchStudentProfile();
    this.fetchSkills();
    window.addEventListener('resize', this.handleResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
