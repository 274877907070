<template>
  <div>
    <div class="page-section border-bottom-2">
      <div :class="containerClass">
        <div class="card">
          <div class="card-body">
            <div v-for="(guide, index) in organizationDashboard" :key="guide.id">
              <guide-row
                :title="guide.title"
                :description="guide.description"
                :isLoading="isLoading"
                :redirect-to="guide.redirectTo"
                :status="getDashboardItemStatus(guide)"
                :icon="guide.icon"
                :btn-text="guide.btnText"
                :btnDisabled="true"
              />
              <hr v-if="index + 1 < organizationDashboard.length" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Page from '@/components/Page.vue';
import GuideRow from '@/views/OrganizationPortal/SetupGuide/GuideRow.vue';
import { get } from 'lodash';
export default {
  components: { GuideRow },
  props: { id: { type: Number, default: () => 0 } },
  extends: Page,

  data() {
    return {
      title: 'Student Checklist',
      orgStatuses: {},
      student: {},
      windowWidth: window.innerWidth,
      isLoading: false,
      organizationDashboard: [
        {
          id: 2,
          title: 'Take a Career Assessment',
          btnText: 'Todo',
          abbr: 'career_assessment',
          description: 'Complete a Basic Assessment to earn 1 point or an Advanced Assessment to earn 5.',
          enable: true,
          icon: 'assessment',
          redirectTo: '',
        },
        {
          title: 'Add Education to Resume',
          btnText: 'Todo',
          abbr: 'education_resume',
          description: 'Earn 1 point for every education entry added to your resume.',
          enable: true,
          icon: 'description',
          redirectTo: '',
        },
        {
          title: 'Add Work Experience to Resume',
          abbr: 'work_experience_resume',
          btnText: 'Todo',
          description: 'Earn 1 point for each work experience entry you include in your resume.',
          enable: true,
          icon: 'description',
          redirectTo: '',
        },
        {
          title: 'Add Volunteer Work Experience to Resume',
          abbr: 'volunteer_experience_resume',
          btnText: 'Todo',
          description: 'Earn 1 point for each volunteer work experience entry you include in your resume.',
          enable: true,
          icon: 'description',
          redirectTo: '',
        },
        {
          title: 'Add Skills to Resume',
          abbr: 'skills_resume',
          btnText: 'Todo',
          description: 'Earn 1 point for each skill entry to your resume.',
          enable: true,
          icon: 'description',
          redirectTo: '',
        },
        {
          title: 'Add Latest Title and Location to Resume',
          abbr: 'latest_title_location_resume',
          btnText: 'Todo',
          description: 'Earn up to 2 points for adding your current job title and location.',
          enable: true,
          icon: 'description',
          redirectTo: '',
        },
        {
          title: 'Practice Skill Simulations',
          abbr: 'practice_skill_simulations',
          btnText: 'Todo',
          description: 'Earn 3 points for completing a real-world skill simulation.',
          enable: true,
          icon: 'mic',
          redirectTo: '',
        },
        {
          title: 'Add Certifications',
          abbr: 'add_certifications',
          btnText: 'Todo',
          description: 'Earn 5 points for each certification you upload to your profile.',
          enable: true,
          icon: 'assignment_turned_in',
          redirectTo: '',
        },
        {
          title: 'Add New Offers',
          abbr: 'add_new_offers',
          btnText: 'Todo',
          description: 'Earn 3 points for every offers entry you include to demonstrate career progress.',
          enable: true,
          icon: 'work',
          redirectTo: '',
        },
      ],
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Dashboard', active: true },
      ];
    },
  },

  methods: {
    get,
    ...mapActions('student', ['getOrgStudChecklist', 'getStudent']),
    getDashboardItemStatus(guide) {
      const points = get(this.orgStatuses, guide.abbr);
      return !!get(points, 'earned');
    },
    async fetchStudent() {
      this.isLoading = true;
      try {
        this.student = (await this.getStudent(this.$route.params.id)).data;
      } catch (ex) {
        this.$router.push({
          name: 'org-checklist-students-list',
          params: {
            ...(this.$route.params.to === 'checklist' && { to: 'checklist' }),
          },
        });
      }
      this.fetchOrgStudChecklist();
    },
    async fetchOrgStudChecklist() {
      // this.isLoading = true;
      try {
        const resp = (
          await this.getOrgStudChecklist({
            student: this.student.id,
          })
        ).data;
        this.orgStatuses = resp.data;
      } catch (_err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },
  },

  async mounted() {
    this.fetchStudent();
  },
};
</script>
