<template>
  <b-modal
    content-class="simulation-modal-content-class"
    dialog-class="simulation-modal-dialog-class"
    modal-class="simulation-modal-class"
    body-class="d-flex flex-column justify-content-center"
    ref="SimulationModal"
    hide-footer
    hide-header
    no-close-on-backdrop
    centered
    lazy
    @hide="hideModal"
    size="md"
  >
    <div class="d-flex flex-column mt-2">
      <div class="text-center">
        <h4 class="wrap-text">{{ simulation.title }}</h4>
      </div>
      <b-alert v-if="callError" class="my-2" show variant="danger">
        <div class="d-flex align-items-center">
          <div class="mr-2">
            <i class="material-icons " style="font-size: 25px">warning</i>
          </div>
          <div>
            Can't start simulation without mic access. Please allow microphone permission in browser settings.
          </div>
        </div>
      </b-alert>

      <div class="d-flex w-full h-full mt-2 mb-2 flex-column">
        <div class="text-center d-flex justify-content-center align-items-center mb-2">
          <div :class="{ pulse: pulse }" class="default-pulse">
            <img :src="getImage(get(simulation, 'agent.voice_id'))" alt="" class="img-fluid" />
          </div>
        </div>

        <transition name="slide">
          <div class="w-full mx-2 d-flex flex-column justify-content-end transcript-container" v-if="isCalling">
            <div v-for="(item, index) in transcript" :key="index">
              <div class="d-flex mb-1">
                <div class="mr-2">
                  <img
                    v-if="item.role === 'agent'"
                    :src="getImage(get(simulation, 'agent.voice_id'))"
                    alt=""
                    class="transcript-img"
                  />

                  <user-avatar
                    v-else
                    slot="aside"
                    :user="getLoggedInUser"
                    size="sm"
                    :file-url="getLoggedInUser.avatar_url"
                    variant="dark"
                  ></user-avatar>
                </div>
                <div>
                  <b class="text-muted">{{ item.role === 'agent' ? 'Agent' : 'You' }}:</b>
                  <span class="ml-1">{{ item.content }}</span>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <div v-if="isCalling" style="font-size: 17px;" class="text-center" :class="{ 'text-danger': callEnding }">
          <strong>
            {{ formatTime(time) }}
          </strong>
        </div>
        <div v-else-if="callEnded" class="text-center">
          Call Ended
        </div>
        <div v-else class="text-center">
          Connecting...
        </div>
        <div class="text-center d-flex justify-content-center mb-2">
          <b-btn class="mt-2" @click="hideModalManual" style="border-radius: 10px;" variant="danger">
            End Simulation <i class="ml-2 fas fa-phone-slash"></i>
          </b-btn>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { get, truncate } from 'lodash';
import { RetellWebClient } from 'retell-client-js-sdk';
import { SIMULATION_VOICES_OPTIONS } from '@/common/constants';
import UserAvatar from '@/components/User/UserAvatar.vue';

export default {
  name: 'SimulationModal',
  components: { UserAvatar },

  props: {
    showModal: { type: Boolean, default: false },
    simulation: { type: Object, default: () => {} },
  },

  data() {
    return {
      isLoading: false,
      time: 0,
      isRunning: false,
      interval: null,
      callEnding: false,
      callEnded: false,
      pulse: false,
      webClient: new RetellWebClient(),
      isCalling: false,
      SIMULATION_VOICES_OPTIONS,
      callError: false,
      startCall: false,
      transcript: [],
      windowWidth: window.innerWidth,
      transcriptTextarea: '',
    };
  },
  validations() {
    return {};
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    isMobileScreen() {
      return this.windowWidth <= 776;
    },
  },
  methods: {
    ...mapActions('simulations', ['registerSimulation']),

    get,
    truncate,

    getImage(voice) {
      if (voice) {
        return require(`@/assets/images/avatars/${
          this.SIMULATION_VOICES_OPTIONS.find(item => item.value === voice).image
        }`);
      }
    },
    async hideModal() {
      this.transcript = [];
      this.$emit('close');
    },
    formatTime(seconds) {
      if (seconds > 300) {
        this.callEnded = true;
        setTimeout(() => this.hideModalManual(), 1000);
      }
      if (seconds > 270) {
        this.callEnding = true;
      }
      const time = new Date(seconds * 1000);
      return time
        .toTimeString()
        .split(' ')[0]
        .slice(3, time.length);
    },
    async hideModalManual() {
      const isConfirmed = await this.$bvModal.msgBoxConfirm(`Are you sure you want to end simulation?`, {
        title: 'Are you sure?',
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      });
      if (isConfirmed) {
        this.toggleConversation();
        this.hideModal();
        this.$refs.SimulationModal.hide();
      }
    },
    toggleTimer() {
      if (this.isRunning) {
        clearInterval(this.interval);
        this.time = 0;
      } else {
        this.interval = setInterval(this.incrementTime, 1000);
      }
      this.isRunning = !this.isRunning;
    },
    incrementTime() {
      this.time = parseInt(this.time) + 1;
    },
    async toggleConversation() {
      if (this.isCalling) {
        this.isCalling = false;
        this.webClient.stopCall();
      } else {
        if (this.startCall) {
          this.startCall = false;
          const response = await this.registerSimulation({ simulation_id: this.simulation.id });

          if (response.data.data.access_token) {
            this.webClient.startCall({
              accessToken: response.data.data.access_token,
              sampleRate: 44000,
            });

            this.webClient.on('call_started', () => {
              this.isCalling = true;
            });
          }
        }
      }
      this.toggleTimer();
    },
  },
  watch: {
    showModal(value) {
      if (value) {
        this.$refs.SimulationModal.show();
        this.startCall = true;
        this.callEnding = false;
        this.callEnded = false;
        this.pulse = false;
        this.transcript = [];

        this.toggleConversation();
      }
    },
  },
  mounted() {
    this.webClient.on('audio', () => {
      // this.pulse = true;
    });

    this.webClient.on('call_ended', () => {
      this.toggleConversation();
      this.$refs.SimulationModal.hide();
      this.hideModal();
    });

    this.webClient.on('error', error => {
      if (error === `User didn't give microphone permission`) {
        this.callError = true;
      }
    });

    // Use the following if you want to show pulse when agent is talking

    // When agent starts talking for the utterance
    // this.webClient.on('agent_start_talking', () => {
    //   this.pulse = true;
    // });

    // When agent is done talking for the utterance

    // this.webClient.on('agent_stop_talking', () => {
    //   this.pulse = false;
    // });

    this.webClient.on('update', update => {
      this.transcript = update.transcript;

      // old animation logic (use if needed)
      // if (update.turntaking === 'user_turn') {
      //   this.pulse = false;
      // } else {
      //   this.pulse = true;
      // }
    });
  },
  created() {
    if (this.isCalling) {
      window.addEventListener('beforeunload', event => {
        event.preventDefault();
        event.returnValue = true;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.content-center {
  padding: 100px;
}

.wrap-text {
  word-wrap: break-word;
  white-space: normal;
  overflow-wrap: break-word;
}

.pulse i {
  color: #fff;
}

.default-pulse i {
  color: #fff;
}

.default-pulse {
  height: 100px;
  width: 100px;
  background-color: #214e6f;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.pulse {
  height: 100px;
  width: 100px;
  background-color: #214e6f;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.pulse::before {
  content: '';
  position: absolute;
  border: 1px solid #214e6f;
  width: calc(100% + 40px);
  height: calc(100% + 40px);
  border-radius: 50%;
  animation: pulse 1s linear infinite;
}

.pulse::after {
  content: '';
  position: absolute;
  border: 1px solid #214e6f;
  width: calc(100% + 40px);
  height: calc(100% + 40px);
  border-radius: 50%;
  animation: pulse 1s linear infinite;
  animation-delay: 0.3s;
  opacity: 0;
}

.transcript-container {
  height: 200px;
  overflow-y: hidden;
}

.transcript-img {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.3);
    opacity: 0;
  }
}

.slide-enter-active,
.slide-leave-active {
  -moz-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}
.slide-enter-to,
.slide-leave {
  max-height: 200px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
