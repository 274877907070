<template>
  <div class="page-section">
    <div :class="containerClass">
      <h2 class="text-center mb-2">Welcome {{ getLoggedInUser.first_name }}!</h2>
      <p class="text-center mb-4" v-if="getLoggedInUserDashboard === DASHBOARD_TYPES.CARECAMPUS">
        Our Student Center is designed to help you be successful. Choose a university to get started with your
        checklist.
      </p>
      <p class="text-center mb-4" v-else>
        Our Student Center is designed to help you be successful. Follow the tasks listed below.
      </p>

      <div v-if="getLoggedInUserDashboard === DASHBOARD_TYPES.SCHOOL" class="row mb-5">
        <template v-for="guide in get(getLoggedInUser, 'linked_entity.std_dashboard_config.dashboard_config') || []">
          <DashboardCard
            v-if="guide.enable"
            :key="guide.id"
            :guide="guide"
            :setupStatuses="setupStatuses"
            class="col-md-6 mb-2"
          />
        </template>
      </div>

      <div v-else-if="getLoggedInUserDashboard === DASHBOARD_TYPES.ORGANIZATION" class="card">
        <div class="card-body">
          <div v-for="(guide, index) in organizationDashboard" :key="guide.id">
            <guide-row
              :title="guide.title"
              :description="guide.description"
              :isLoading="false"
              :redirect-to="guide.redirectTo"
              :status="orgStatuses"
              :icon="guide.icon"
              :abbr="guide.abbr"
              :btn-text="guide.btnText"
            />
            <hr v-if="index + 1 < organizationDashboard.length" />
          </div>
        </div>
      </div>

      <div v-else-if="getLoggedInUserDashboard === DASHBOARD_TYPES.CARECAMPUS">
        <dash-universities-list />
      </div>
    </div>
  </div>
</template>

<script>
import { get, isEmpty } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Page from '../../../components/Page.vue';
import DashboardCard from '@/components/DashboardCard.vue';
import GuideRow from './GuideRow.vue';
import DashUniversitiesList from './DashUniversitiesList.vue';
import { DASHBOARD_TYPES } from '@/common/constants';

export default {
  extends: Page,
  name: 'SchoolSetupGuide',
  components: { DashboardCard, GuideRow, DashUniversitiesList },
  data() {
    return {
      title: 'Home',

      isLoading: false,
      setupStatuses: {
        uploaded_docs: true,
        question_asked: true,
        resume_build: true,
        payments_paid: true,
        practice_skills: '',
        jobs: '',
        employers: '',
      },
      orgStatuses: {},
      status: null,
      windowWidth: window.innerWidth,
      showPrivacyModal: false,
      DASHBOARD_TYPES,

      organizationDashboard: [
        {
          id: 2,
          title: 'Take a Career Assessment',
          abbr: 'career_assessment',
          btnText: 'Complete',
          description: 'Complete a Basic Assessment to earn 1 point or an Advanced Assessment to earn 5.',
          enable: true,
          icon: 'assessment',
          redirectTo: '',
        },
        {
          title: 'Add Education to Resume',
          abbr: 'education_resume',
          btnText: 'Complete',
          description: 'Earn 1 point for every education entry added to your resume.',
          enable: true,
          icon: 'description',
          redirectTo: 'std-manage-resume',
        },
        {
          title: 'Add Work Experience to Resume',
          abbr: 'work_experience_resume',
          btnText: 'Complete',
          description: 'Earn 1 point for each work experience entry you include in your resume.',
          enable: true,
          icon: 'description',
          redirectTo: 'std-manage-resume',
        },
        {
          title: 'Add Volunteer Work Experience to Resume',
          abbr: 'volunteer_experience_resume',
          btnText: 'Complete',
          description: 'Earn 1 point for each volunteer work experience entry you include in your resume.',
          enable: true,
          icon: 'description',
          redirectTo: 'std-manage-resume',
        },
        {
          title: 'Add Skills to Resume',
          abbr: 'skills_resume',
          btnText: 'Complete',
          description: 'Earn 1 point for each skill entry to your resume.',
          enable: true,
          icon: 'description',
          redirectTo: 'std-manage-resume',
        },
        {
          title: 'Add Latest Title and Location to Resume',
          abbr: 'latest_title_location_resume',
          btnText: 'Complete',
          description: 'Earn up to 2 points for adding your current job title and location.',
          enable: true,
          icon: 'description',
          redirectTo: 'std-manage-resume',
        },
        {
          title: 'Practice Skill Simulations',
          abbr: 'practice_skill_simulations',
          btnText: 'Complete',
          description: 'Earn 3 points for completing a real-world skill simulation.',
          enable: true,
          icon: 'mic',
          redirectTo: 'student-simulations-listing',
        },
        {
          title: 'Add Certifications',
          abbr: 'add_certifications',
          btnText: 'Complete',
          description: 'Earn 5 points for each certification you upload to your profile.',
          enable: true,
          icon: 'assignment_turned_in',
          redirectTo: 'add-certificate',
        },
        {
          title: 'Add New Offers',
          abbr: 'add_new_offers',
          btnText: 'Complete',
          description: 'Earn 3 points for every offers entry you include to demonstrate career progress.',
          enable: true,
          icon: 'work',
          redirectTo: 'add-offer',
        },
        
      ],
    };
  },

  computed: {
    ...mapGetters('navbarConfig', ['getLinkedEntity']),
    ...mapGetters('auth', ['getLoggedInUser', 'getLoggedInUserDashboard']),
    ...mapGetters('school', ['getPaymentStatus']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Setup Guide', active: true },
      ];
    },
    isMobileScreen() {
      return this.windowWidth <= 600;
    },
  },

  methods: {
    ...mapActions('student', ['getStudentDashboardStatus', 'getDashboardScore']),
    get,
    isEmpty,

    loadVoiceflow() {
      var stdBotApiKey = this.getLoggedInUser.linked_entity.std_bot_api_key;
      var id = this.getLoggedInUser.id.toString();
      var v = document.createElement('script'),
        s = document.getElementsByTagName('script')[0];

      v.onload = function() {
        window.voiceflow.chat.load({
          verify: { projectID: stdBotApiKey },
          url: 'https://general-runtime.voiceflow.com',
          versionID: 'production',
          userID: id,
        });
      };

      v.src = 'https://cdn.voiceflow.com/widget/bundle.mjs';
      v.type = 'text/javascript';
      s.parentNode.insertBefore(v, s);
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },

    async fetchDashboardStatus() {
      this.isLoading = true;
      try {
        this.setupStatuses = (await this.getStudentDashboardStatus()).data;
      } catch (_err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },

    async fetchDashboardScore() {
      this.isLoading = true;
      try {
        this.orgStatuses = (
          await this.getDashboardScore({
            ...(this.getLoggedInUserDashboard !== DASHBOARD_TYPES.CARECAMPUS && {
              id: this.getLoggedInUser.linked_entity.id,
              entity_type: 'organization',
            }),
          })
        ).data;
      } catch (_err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },
  },

  watch: {
    getPaymentStatus: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          this.status = value;
        }
      },
    },
  },

  async mounted() {
    if (this.getLoggedInUser && this.getLoggedInUser.linked_entity) {
      if (this.getLoggedInUserDashboard === DASHBOARD_TYPES.SCHOOL) {
        this.loadVoiceflow();
        this.fetchDashboardStatus();
      }
      if (this.getLoggedInUserDashboard === DASHBOARD_TYPES.ORGANIZATION) {
        this.fetchDashboardScore();
      }
    }
    window.addEventListener('resize', this.handleResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
</style>
